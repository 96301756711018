export type BodilyInjuryPerspective = 'front' | 'back';

export type BodilyInjuryRegion =
  | 'head'
  // | 'neck'
  | 'shoulder_left'
  | 'shoulder_right'
  | 'arm_left'
  | 'arm_right'
  | 'hand_left'
  | 'hand_right'
  | 'chest'
  | 'torso'
  | 'upper_leg_left'
  | 'upper_leg_right'
  | 'lower_leg_left'
  | 'lower_leg_right'
  | 'foot_left'
  | 'foot_right'
  | 'back';

// 0 = No pain; 10 = Max pain
type PainRating = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface Region {
  key: BodilyInjuryRegion;
  options: {
    key: string;
    data: { [field: string]: boolean };
    manual_input?: string;
  }[];
  current_pain_rating?: PainRating;
}

export interface BodilyInjuryPayload {
  regions: Region[];
}

export type BodilyInjuryDisplayMode =
  | 'auto'
  | 'workers_comp'
  | 'property'
  | 'liability';

export const DisplayMode: { [k: string]: BodilyInjuryDisplayMode } = {
  AUTO: 'auto',
  WORKERS_COMP: 'workers_comp',
  PROPERTY: 'property',
  LIABILITY: 'liability',
};

export type TemplateTabs = {
  key: string;
  label: string;
  display_in_modes?: BodilyInjuryDisplayMode[];
};

export type TemplateOption = {
  key: string;
  tabs: string[];
  label: string;
  popular_in_modes?: BodilyInjuryDisplayMode[];
  hidden_in_modes?: BodilyInjuryDisplayMode[];
  display_in_modes?: BodilyInjuryDisplayMode[];
  hide_if_low_severity?: BodilyInjuryDisplayMode[];
  followups?: { key: string; label: string }[];
};

export interface RegionTemplate {
  tabs: TemplateTabs[];
  options: TemplateOption[];
}

export type BodilyInjuryRegionTemplates =
  | 'head'
  // | 'neck'
  | 'shoulder'
  | 'arm'
  | 'hand'
  | 'chest'
  | 'torso'
  | 'back'
  | 'upper_leg'
  | 'lower_leg'
  | 'foot';

export type RegionTemplates = {
  [k in BodilyInjuryRegionTemplates]: RegionTemplate;
};

export type BodilyInjuryRegionTabs = {
  key: BodilyInjuryRegion;
  label: string;
  data: RegionTemplate;
};
