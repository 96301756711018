import { DisplayMode, RegionTemplate } from './types';

export const chest: { chest: RegionTemplate } = {
  chest: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'ribs',
        label: 'Ribs',
      },
      {
        key: 'collarbone',
        label: 'Collarbone',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
      {
        key: 'lung',
        label: 'Lung',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'heart',
        label: 'Heart',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'organs',
            label: 'Were organs damaged?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'seatbelt_bruise',
        label: 'Seatbelt bruise',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'broken_rib',
        label: 'Broken rib',
        tabs: ['ribs'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_rib',
        label: 'Bruised rib',
        tabs: ['ribs'],
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
      },
      {
        key: 'broken_collarbone',
        label: 'Broken collarbone',
        tabs: ['collarbone'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
        ],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_collarbone',
        label: 'Bruised collarbone',
        tabs: ['collarbone'],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: [],
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
      },

      {
        key: 'silicosis',
        label: 'Silicosis',
        tabs: ['lung'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'asbestosis',
        label: 'Asbestosis',
        tabs: ['lung'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'blackLung',
        label: 'Black lung',
        tabs: ['lung'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'byssinosis',
        label: 'Byssinosis',
        tabs: ['lung'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'rupture',
        label: 'Rupture',
        tabs: ['lung'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'heartAttack',
        label: 'Heart Attack',
        tabs: ['heart'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['heart'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'anginaPectoris',
        label: 'Angina Pectoris',
        tabs: ['heart'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'burn_scald',
        label: 'Burn / scald',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'majorBurn',
            label: 'Major burn?',
          },
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'dermatitis',
        label: 'Dermatitis',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'infection',
        label: 'Infection',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'heart_inflammation',
        label: 'Inflammation',
        tabs: ['heart'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'heart_laceration',
        label: 'Laceration',
        tabs: ['heart'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'heart_infection',
        label: 'Infection',
        tabs: ['heart'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'lung_inflammation',
        label: 'Inflammation',
        tabs: ['lung'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'lung_laceration',
        label: 'Laceration',
        tabs: ['lung'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'lung_infection',
        label: 'Infection',
        tabs: ['lung'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
  },
};
